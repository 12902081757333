import React, { useEffect, useState } from 'react';
import { Grid, TextField, Theme } from '@mui/material';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import SwitchButton from '../Components/SwitchButton';
import CatalogConfigCard from '../Components/CatalogConfigCard';
import { getKeyExtractorComparer } from '../Utils/Comparers';
import Substances from './Substances';
import MultiSelect from '../Components/MultiSelect';
import { IProduct, ICategory, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'lightgrey',
      fontSize: '0.68rem',
    },
  })
);

const tabs = {
  Characteristics: 'Charakteristika produktu',
  Manufacturers: 'Výrobci',
  Suppliers: 'Dodavatelé na trh',
  Brandmarks: 'Značky',
  'Product series': 'Produktové řady',
  Unit: 'Formy',
  SimpleForm: 'Základní Formy',
  Drug: 'Účinné látky',
  PharmaGroup: 'Farmakoterapeutické skupiny',
  Substances: 'Pomocné látky',
};

export default function CatalogConfig() {
  const [selectsConfig, setSelectsConfig] = useState<ISelectsConfig | undefined>(undefined);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [currentTab, setCurrentTab] = useState<keyof typeof tabs>('Characteristics');
  const [categories, setCategories] = useState<ICategory[]>([]);
  const layout = useLayout();
  const classes = useStyles();

  const addRow = (value: string, category: string, success: (id: number) => void) => {
    catalogService.post(
      '/settings/selects',
      {
        value: value,
        category: category,
      },
      null,
      {
        success: success,
        error: () => layout.error('Při ukládání do databáze došlo k chybě'),
      }
    );
  };

  useEffect(() => {
    layout.setIsLoading(true);
    catalogService.get(
      '/settings/selects/',
      {},
      {
        success: (conf: ISelectsConfig) => {
          layout.setIsLoading(false);
          setSelectsConfig({
            brandmarks: conf.brandmarks.sort(getKeyExtractorComparer('value')),
            characteristics: conf.characteristics.sort(getKeyExtractorComparer('text')),
            manufacturers: conf.manufacturers.sort(getKeyExtractorComparer('value')),
            productSeries: conf.productSeries.sort(getKeyExtractorComparer('value')),
            suppliers: conf.suppliers.sort(getKeyExtractorComparer('name')),
            drugs: conf.drugs.sort(getKeyExtractorComparer('value')),
            units: conf.units.sort(getKeyExtractorComparer('first')),
            simpleForms: conf.simpleForms.sort(getKeyExtractorComparer('name')),
            pharmaGroups: conf.pharmaGroups.sort(getKeyExtractorComparer('value')),
          });
        },
        error: layout.error,
      }
    );
    catalogService.get(
      '/categories/noauth/tree',
      {},
      {
        success: setCategories,
        error: console.log,
      }
    );
    catalogService.get('/products/all', { full: true }, { success: setProducts, error: console.log });
    // eslint-disable-next-line
  }, []);

  return (
    (selectsConfig && (
      <>
        <SwitchButton data={tabs} value={currentTab} onChange={setCurrentTab as (x: string) => void} />
        {currentTab === 'Characteristics' ? (
          <CatalogConfigCard
            title="Charakteristika produktu"
            data={selectsConfig.characteristics}
            rowGenerator={(ch, i) => (
              <Grid container key={i} spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={ch.text}
                    onChange={(e) =>
                      setSelectsConfig({
                        ...selectsConfig,
                        characteristics: selectsConfig.characteristics.map((char, ind) =>
                          ind === i ? { ...ch, text: e.target.value } : char
                        ),
                      })
                    }
                    onBlur={(e) =>
                      catalogService.post(
                        '/settings/selects',
                        {
                          id: ch.id,
                          details: ch.warning,
                          value: ch.text,
                          category: 'CHARACTERISTICS',
                        },
                        null,
                        {
                          success: () => {},
                          error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                        }
                      )
                    }
                    label={`Charakteristika (${products.filter((p) => p.characteristics?.toString() === ch.id.toString()).length})`}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={ch.warning}
                    onChange={(e) =>
                      setSelectsConfig({
                        ...selectsConfig,
                        characteristics: selectsConfig.characteristics.map((char, ind) =>
                          ind === i ? { ...ch, warning: e.target.value } : char
                        ),
                      })
                    }
                    onBlur={(e) =>
                      catalogService.post(
                        '/settings/selects',
                        {
                          id: ch.id,
                          details: ch.warning,
                          value: ch.text,
                          category: 'CHARACTERISTICS',
                        },
                        null,
                        {
                          success: () => {},
                          error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                        }
                      )
                    }
                    label="Upozornění"
                  />
                </Grid>
              </Grid>
            )}
            addRow={(ch) =>
              addRow(ch, 'CHARACTERISTICS', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  characteristics: [...selectsConfig.characteristics, { id: id, text: ch, warning: '' }],
                })
              )
            }
          />
        ) : currentTab === 'Brandmarks' ? (
          <CatalogConfigCard
            title="Značky"
            data={selectsConfig.brandmarks}
            rowGenerator={(bm, i) => (
              <>
                <TextField
                  variant="standard"
                  key={i}
                  value={bm.value}
                  onChange={(e) =>
                    setSelectsConfig({
                      ...selectsConfig,
                      brandmarks: selectsConfig.brandmarks.map((b, ind) =>
                        i === ind ? { ...b, value: e.target.value } : b
                      ),
                    })
                  }
                  onBlur={(e) =>
                    catalogService.post(
                      '/settings/selects',
                      {
                        id: bm.id,
                        value: bm.value,
                        category: 'BRAND_MARK',
                      },
                      null,
                      {
                        success: () => {},
                        error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                      }
                    )
                  }
                  fullWidth
                  label={products.filter((p) => p.brandMark?.toString() === bm.id.toString()).length}
                />
                <a
                  className={classes.link}
                  href={`https://magistrmartin.cz/offer/brand/${bm.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://magistrmartin.cz/offer/brand/{bm.value}
                </a>
              </>
            )}
            addRow={(b) =>
              addRow(b, 'BRAND_MARK', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  brandmarks: [...selectsConfig.brandmarks, { id: id, value: b }],
                })
              )
            }
            columns={4}
          />
        ) : currentTab === 'Product series' ? (
          <CatalogConfigCard
            title="Produktové řady"
            rowGenerator={(ps, i) => (
              <>
                <TextField
                  variant="standard"
                  key={i}
                  value={ps.value}
                  onChange={(e) =>
                    setSelectsConfig({
                      ...selectsConfig,
                      productSeries: selectsConfig.productSeries.map((p, ind) =>
                        i === ind ? { ...p, value: e.target.value } : p
                      ),
                    })
                  }
                  onBlur={(e) =>
                    catalogService.post(
                      '/settings/selects',
                      {
                        id: ps.id,
                        value: ps.value,
                        category: 'PRODUCT_SERIE',
                      },
                      null,
                      {
                        success: () => {},
                        error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                      }
                    )
                  }
                  label={products.filter((p) => p.series?.toString() === ps.id.toString()).length}
                  fullWidth
                />
                <a
                  className={classes.link}
                  href={`https://magistrmartin.cz/offer/productLine/${ps.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://magistrmartin.cz/offer/productLine/{ps.value}
                </a>
              </>
            )}
            data={selectsConfig.productSeries}
            addRow={(p) =>
              addRow(p, 'PRODUCT_SERIE', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  productSeries: [...selectsConfig.productSeries, { id: id, value: p }],
                })
              )
            }
            columns={4}
          />
        ) : currentTab === 'Manufacturers' ? (
          <CatalogConfigCard
            title="Výrobci"
            data={selectsConfig.manufacturers}
            rowGenerator={(man, i) => (
              <Grid container key={i} spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="standard"
                  key={i}
                  value={man.value}
                  onChange={(e) =>
                    setSelectsConfig({
                      ...selectsConfig,
                      manufacturers: selectsConfig.manufacturers.map((m, ind) =>
                        i === ind ? { ...m, value: e.target.value } : m
                      ),
                    })
                  }
                  onBlur={(e) =>
                    catalogService.post(
                      '/settings/selects',
                      {
                        id: man.id,
                        value: man.value,
                        category: 'MANUFACTURER',
                        address: man.address
                      },
                      null,
                      {
                        success: () => {},
                        error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                      }
                    )
                  }
                  label={products.filter((p) => p.manufacturer?.toString() === man.id.toString()).length}
                  fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                <TextField
                    variant="standard"
                  key={i}
                  value={man.address}
                  onChange={(e) =>
                    setSelectsConfig({
                      ...selectsConfig,
                      manufacturers: selectsConfig.manufacturers.map((m, ind) =>
                        i === ind ? { ...m, address: e.target.value } : m
                      ),
                    })
                  }
                  onBlur={(e) =>
                    catalogService.post(
                      '/settings/selects',
                      {
                        id: man.id,
                        value: man.value,
                        category: 'MANUFACTURER',
                        address: man.address,
                      },
                      null,
                      {
                        success: () => {},
                        error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                      }
                    )
                  }
                  label={"Adresa"}
                  fullWidth
                  />
                </Grid>
              </Grid>
              
            )}
            addRow={(m) =>
              addRow(m, 'MANUFACTURER', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  manufacturers: [...selectsConfig.manufacturers, { id: id, name: m, address: '' }],
                })
              )
            }
            columns={4}
          />
        ) : currentTab === 'Suppliers' ? (
          <CatalogConfigCard
            title="Dodavatelé na trh"
            data={selectsConfig.suppliers}
            rowGenerator={(sup, i) => (
              <Grid container spacing={2}>
                <Grid key={i} item xs={12} md={6}>
                  <>
                    <TextField
                      variant="standard"
                      value={sup.name}
                      label={`Dodavatel (${products.filter((p) => p.supplier?.toString() === sup.id.toString()).length})`}
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          suppliers: selectsConfig.suppliers.map((s, ind) =>
                            i === ind ? { ...s, name: e.target.value } : s
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: sup.id,
                            value: sup.name,
                            details: sup.tag,
                            category: 'SUPPLIER',
                            address: sup.address
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      fullWidth
                    />
                    <a
                      className={classes.link}
                      href={`https://magistrmartin.cz/offer/supplier/${sup.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://magistrmartin.cz/offer/supplier/{sup.name}
                    </a>
                  </>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="standard"
                    key={i}
                    value={sup.tag}
                    label="Tag"
                    onChange={(e) =>
                      setSelectsConfig({
                        ...selectsConfig,
                        suppliers: selectsConfig.suppliers.map((s, ind) =>
                          i === ind ? { ...s, tag: e.target.value } : s
                        ),
                      })
                    }
                    onBlur={(e) =>
                      catalogService.post(
                        '/settings/selects',
                        {
                          id: sup.id,
                          value: sup.name,
                          details: sup.tag,
                          category: 'SUPPLIER',
                          address: sup.address
                        },
                        null,
                        {
                          success: () => {},
                          error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                        }
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                      variant='standard'
                      key={i}
                      value={sup.address}
                      label="Adresa"
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          suppliers: selectsConfig.suppliers.map((s, ind) =>
                            i === ind ? { ...s, address: e.target.value } : s
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: sup.id,
                            value: sup.name,
                            details: sup.tag,
                            category: 'SUPPLIER',
                            address: sup.address
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      fullWidth
                    />
                </Grid>
              </Grid>
            )}
            addRow={(s) =>
              addRow(s, 'SUPPLIER', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  suppliers: [...selectsConfig.suppliers, { id: id, name: s, tag: '', address: '' }],
                })
              )
            }
            columns={2}
          />
        ) : currentTab === 'Drug' ? (
          <>
            <CatalogConfigCard
              title="Účinné látky"
              data={selectsConfig.drugs}
              rowGenerator={(d, i) => (
                <>
                  <TextField
                    variant="standard"
                    key={i}
                    value={d.value}
                    onChange={(e) =>
                      setSelectsConfig({
                        ...selectsConfig,
                        drugs: selectsConfig.drugs.map((drug, ind) =>
                          i === ind ? { ...drug, value: e.target.value } : drug
                        ),
                      })
                    }
                    onBlur={(e) =>
                      catalogService.post(
                        '/settings/selects',
                        {
                          id: d.id,
                          value: d.value,
                          category: 'ACTIVE_SUBSTANCE',
                        },
                        null,
                        {
                          success: () => {},
                          error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                        }
                      )
                    }
                    label={products.filter((p) => p.activeSubstances?.includes(d.id.toString())).length}
                    fullWidth
                  />
                  <a
                    className={classes.link}
                    href={`https://magistrmartin.cz/offer/activeSubstance/${d.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://magistrmartin.cz/offer/activeSubstance/{d.value}
                  </a>
                </>
              )}
              addRow={(d) =>
                addRow(d, 'ACTIVE_SUBSTANCE', (id) =>
                  setSelectsConfig({
                    ...selectsConfig,
                    drugs: [...selectsConfig.drugs, { id: id, value: d }],
                  })
                )
              }
              columns={4}
            />
          </>
        ) : currentTab === 'Unit' ? (
          <>
            <CatalogConfigCard
              title="Formy"
              data={selectsConfig.units}
              rowGenerator={(u: (typeof selectsConfig.units)[0], i) => (
                <Grid container key={i} spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={u.first}
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          units: selectsConfig.units.map((unit, ind) =>
                            ind === i ? { ...u, first: e.target.value } : unit
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: u.id,
                            value: u.shortForm,
                            details: `${u.first};${u.second};${u.third}`,
                            category: 'UNIT',
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      label={`Název formy (${products.filter((p) => p.form?.toString() === u.id?.toString()).length})`}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={u.shortForm}
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          units: selectsConfig.units.map((unit, ind) =>
                            ind === i ? { ...u, shortForm: e.target.value } : unit
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: u.id,
                            value: u.shortForm,
                            details: `${u.first};${u.second};${u.third}`,
                            category: 'UNIT',
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      label="Zkratka"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={u.second}
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          units: selectsConfig.units.map((unit, ind) =>
                            ind === i ? { ...u, second: e.target.value } : unit
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: u.id,
                            value: u.shortForm,
                            details: `${u.first};${u.second};${u.third}`,
                            category: 'UNIT',
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      label="Jednotka"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={u.third}
                      onChange={(e) =>
                        setSelectsConfig({
                          ...selectsConfig,
                          units: selectsConfig.units.map((unit, ind) =>
                            ind === i ? { ...u, third: e.target.value } : unit
                          ),
                        })
                      }
                      onBlur={(e) =>
                        catalogService.post(
                          '/settings/selects',
                          {
                            id: u.id,
                            value: u.shortForm,
                            details: `${u.first};${u.second};${u.third}`,
                            category: 'UNIT',
                          },
                          null,
                          {
                            success: () => {},
                            error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                          }
                        )
                      }
                      label="Jednotka zkratka"
                    />
                  </Grid>
                </Grid>
              )}
              addRow={(u) =>
                addRow(u, 'UNIT', (id) =>
                  setSelectsConfig({
                    ...selectsConfig,
                    units: [...selectsConfig.units, { id: id, first: u, second: u, third: u, shortForm: u }],
                  })
                )
              }
            />
          </>
        ) : currentTab === 'PharmaGroup' ? (
          <CatalogConfigCard
            title="Farmakoterapeutické skupiny"
            data={selectsConfig.pharmaGroups}
            rowGenerator={(pg, i) => (
              <TextField
                variant="standard"
                key={i}
                value={pg.value}
                onChange={(e) =>
                  setSelectsConfig({
                    ...selectsConfig,
                    pharmaGroups: selectsConfig.pharmaGroups.map((p, ind) =>
                      i === ind ? { ...p, value: e.target.value } : p
                    ),
                  })
                }
                onBlur={(e) =>
                  catalogService.post(
                    '/settings/selects',
                    {
                      id: pg.id,
                      value: pg.value,
                      category: 'PHARMA_GROUP',
                    },
                    null,
                    {
                      success: () => {},
                      error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                    }
                  )
                }
                // label={products.filter((p) => p.manufacturer?.toString() === man.id.toString()).length} TODO
                fullWidth
              />
            )}
            addRow={(p) =>
              addRow(p, 'PHARMA_GROUP', (id) =>
                setSelectsConfig({
                  ...selectsConfig,
                  pharmaGroups: [...selectsConfig.pharmaGroups, { id: id, value: p }],
                })
              )
            }
            columns={4}
          />
        ) : currentTab === 'SimpleForm' ? (
          <>
            {categories.map((c) => (
              <CatalogConfigCard
                title={c.description}
                data={selectsConfig.simpleForms.filter((s) => s.category === c.id)}
                rowGenerator={(u: (typeof selectsConfig.simpleForms)[0], i) => (
                  <Grid container key={i} spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        variant="standard"
                        fullWidth
                        value={u.name}
                        onChange={(e) =>
                          setSelectsConfig({
                            ...selectsConfig,
                            simpleForms: selectsConfig.simpleForms.map((sf, ind) =>
                              ind === i ? { ...u, name: e.target.value } : sf
                            ),
                          })
                        }
                        onBlur={(e) =>
                          catalogService.post(
                            '/settings/selects',
                            {
                              id: u.id,
                              value: u.name,
                              details: `${c.id};${u.bindUnits.join(';')}`,
                              category: 'SIMPLE_FORM',
                            },
                            null,
                            {
                              success: () => {},
                              error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                            }
                          )
                        }
                        label="Název"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <MultiSelect
                        label="Napojené lékové formy"
                        addLabel="Přidat lékovou formu"
                        chipLabelSelector={(val, options) => options.filter((o) => o.id.toString() === val)[0]?.first}
                        keyPropFn={(unit) => unit.id}
                        valuePropFn={(unit) => unit.first}
                        onChange={(newVal) => {
                          catalogService.post(
                            '/settings/selects',
                            {
                              id: u.id,
                              value: u.name,
                              details: `${c.id};${newVal.join(';')}`,
                              category: 'SIMPLE_FORM',
                            },
                            null,
                            {
                              success: () =>
                                setSelectsConfig({
                                  ...selectsConfig,
                                  simpleForms: selectsConfig.simpleForms.map((sf, ind) =>
                                    ind === i ? { ...u, bindUnits: newVal.map((a) => parseInt(a)) } : sf
                                  ),
                                }),
                              error: () => layout.error('Při ukládání do databáze došlo k chybě'),
                            }
                          );
                        }}
                        options={selectsConfig.units}
                        value={u.bindUnits.map((i) => i.toString()) || []}
                        showAll={true}
                      />
                    </Grid>
                  </Grid>
                )}
                addRow={(u) =>
                  addRow(u, 'SIMPLE_FORM', (id) =>
                    setSelectsConfig({
                      ...selectsConfig,
                      simpleForms: [...selectsConfig.simpleForms, { name: u, id: id, category: c.id, bindUnits: [] }],
                    })
                  )
                }
              />
            ))}
          </>
        ) : (
          <Substances products={products} />
        )}
      </>
    )) || <>Načítání</>
  );
}
